@font-face {
  font-family: halebopmedium;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.svg#halebopmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: haleboplight;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.svg#haleboplight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: halebopxtrabold;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.svg#halebopxtrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-layer-background: #f3f7f8;
  --second-layer-background: #fff;
  --line-color: #abacaf;
  --primary-text-color: #fff;
  --primary-color: #2a2a2b;
  --primary-hover-color: #2a2a2b;
  --purchase-button-text-color: #fff;
  --purchase-button: #2a2a2b;
  --purchase-button-hover: #2a2a2b;
  --purchase-button-focus: #2a2a2b;
  --purchase-button-disabled: #eff2f5;
  --font-family: "halebopmedium";
}

/*# sourceMappingURL=index.2fd7e729.css.map */
